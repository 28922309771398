import "./RatingBox.scss";

export default function RatingBox(): JSX.Element {
  return (
    <section className="rating-box">
      <h3>Das sagen unsere Kunden</h3>
      <div className="rating-box-items">
        <div className="rating-box-content-item">
          Kurzfristig ein Termin bekommen. Ich war aufgrund meiner Figur ja ehr
          negativ gestimmt was zu finden🙈 bzw habe mich schon im übernächsten
          Laden gesehen. Hatte mehrere Kleider an und zum schluss sogar zwei die
          mir sehr gefielen! Dazu konnte ich dort auch gleich die passenden
          Schuhe, Schal und Haarschmuck erwerben. Die Inhaberin Frau Bachmann
          berät kompetent, Sie wartet ab und lässt das Kleid erst mal auf einen
          angezogen wirken, sagt aber wie sie darüber denkt. Das mög für manche
          vielleicht nicht "feinfühlig" genug sein, aber ich brauche keinen der
          mir nur was verkaufen will und zu allem sagt wie toll ich darin
          aussehe, ich brauche auch keine Flasche Sekt um dann benebelt ein
          Kleid auszusuchen. Dank ihr konnten wir auch noch am selben Tag zur
          Näherin, Danke.
        </div>
        <div className="rating-box-content-item">
          Dieser Hochzeitsausstatter hat schon meine erste Tochter wunderbar
          beraten und nun auch meine zweite Tochter. Sie sind sehr geduldig und
          sehr freundlich und nimmt sich viel Zeit, alle Wünsche zu erfüllen.
          Hier würde ich immer wieder hingehen und würde diesen
          Hochzeitsausstatter jedem mit guten Gewissen empfehlen. Vielen Dank :)
        </div>
        <div className="rating-box-content-item">
          professionelle Beratung für Braut und Bräutigam, hier fühlt man sich
          sofort verstanden, bei der Findungsphase wurden wir einfühlig
          unterstützt und die Auswahl ist sehr vielfältig, wir haben beide
          unsere kompletten Outfits hier gefunden bis zum letzten Detail wie
          Haarschmuck und Manschettenknöpfe
        </div>
      </div>
    </section>
  );
}
