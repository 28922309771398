import "./BrautigamIcon.scss";

export default function BrautigamIcon({ width = 32, height =32 }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28.575004 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="groom-icon"
    >
      <g transform="matrix(0.35277777,0,0,-0.35277777,13.618357,27.137846)">
        <path
          fillRule="nonzero"
          stroke="none"
          d="M 0,0 V -6.638 H 8.615 V 0 C 6.854,-1.356 5.743,-2.034 5.284,-2.034 Z m -15.277,16.745 h 0.574 c 1.034,0 2.987,-0.858 5.858,-2.572 0,0.558 1.57,1.415 4.71,2.572 h 1.091 c 0.497,0 0.862,-0.957 1.091,-2.871 v -0.897 c -0.229,-1.914 -0.594,-2.871 -1.091,-2.871 h -0.574 c -1.264,0 -3.102,0.857 -5.514,2.572 -2.757,-1.715 -4.518,-2.572 -5.284,-2.572 h -0.861 c -0.613,0 -1.072,1.156 -1.378,3.469 0.344,2.113 0.804,3.17 1.378,3.17 m -8.04,32.114 h -0.287 c -0.345,1.235 -0.518,2.093 -0.518,2.571 v 1.196 c 0,0.678 0.46,2.312 1.379,4.904 -3.715,-4.306 -5.571,-8.931 -5.571,-13.874 0,-9.01 4.441,-15.469 13.324,-19.376 2.412,-0.758 4.537,-1.136 6.375,-1.136 8.193,0 14.224,4.425 18.092,13.276 0.919,2.512 1.378,5.023 1.378,7.535 0,7.016 -3.427,12.798 -10.281,17.343 H 0.287 c -5.628,-2.871 -9.324,-4.605 -11.084,-5.203 -3.331,0 -6.854,-1.636 -10.568,-4.904 -1.072,-1.555 -1.723,-2.332 -1.952,-2.332 m 12.233,26.014 h 1.665 c 1.149,0 3.005,-1.834 5.571,-5.502 1.034,1.555 2.048,2.333 3.044,2.333 2.335,0 5.379,-1.935 9.132,-5.802 4.25,-5.621 6.375,-12.758 6.375,-21.409 C 14.014,38.872 12.826,34.545 11.142,31.516 6.241,23.622 -0.517,19.675 -9.132,19.675 c -9.266,0 -16.215,5.003 -20.847,15.011 -2.031,4.744 -3.044,9.468 -3.044,14.173 v 4.904 c 0,11.561 1.013,17.342 3.044,17.342 6.776,2.512 13.074,3.768 18.895,3.768 M -20.791,16.146 -9.993,-0.598 h 0.287 c 8.117,11.162 12.29,16.744 12.52,16.744 h 0.23 C 7.83,14.193 11.716,9.867 14.703,3.169 c 2.22,-8.213 3.331,-12.837 3.331,-13.874 l -0.517,-0.299 h -53.584 c -0.384,0 -0.575,0.199 -0.575,0.598 2.297,11.522 5.36,19.037 9.189,22.546 2.987,2.671 5.207,4.006 6.662,4.006"
        />
      </g>
    </svg>
  );
}

type Props = {
  width?: number;
  height?: number;
}
