export default function HintBoxSix(): JSX.Element {
  return (
    <div>
      <h3>Tipp 6: Noch 6 Wochen bis zur Hochzeit</h3>
      <p>
        Ihr Termin bei Ihrem Hochzeitsausstatter! Probieren Sie Brautkleid und
        Anzug an, falls nötig ändern lassen. Kaufen Sie jetzt Ihre Trauringe.
        Auch Trauringe müssen noch bearbeitet werden. Die Ringgröße soll
        stimmen, und in einen Ehering gehört eine Gravur. Denken Sie auch an ein
        Ringkissen für die kirchliche Trauung.
      </p>

      <p>
        Überprüfen Sie noch einmal ihre Gästeliste. Haben Sie niemanden
        vergessen - gibt es neue Personen aus ihrem Umfeld? Sind Sie sich einig
        über einen Ehevertrag? Wenn Sie ihn abschließen wollen, dann sollten Sie
        ihn jetzt aufsetzen und vom Notar beurkunden lassen (unbedingt
        erforderlich bei Gütertrennung).
      </p>
    </div>
  );
}
