import "./Footer.scss";
import {Link} from "react-router-dom";

export default function Footer(): JSX.Element {
  return (
    <footer className="footer">
      <div className="footer-content">
        <address className="footer-content-address">
          <span>Hochzeitsausstatter Bachmann</span>
          <span>Wurzner Straße 27</span>
          <span>D-04425 Taucha</span>
        </address>
        <section className="footer-content-open">
          <span>Wir haben geöffnet für Sie:</span>
          <ul className="footer-content-open-time">
            <li>Mo: 10 - 12 Uhr und 13 - 18 Uhr</li>
            <li>Di: 10 - 12 Uhr und 13 - 22 Uhr</li>
            <li>Mi: 10 - 12 Uhr und 13 - 18 Uhr</li>
            <li>Do: 10 - 12 Uhr und 13 - 22 Uhr</li>
            <li>Fr: 10 - 12 Uhr und 13 - 18 Uhr</li>
            <li>Sa: nach Vereinbarung</li>
          </ul>
        </section>
        <section className="footer-content-links">
          <Link to="/datenschutz">Datenschutz</Link>
          <Link to="/impressum">Impressum</Link>
          <Link to="https://www.facebook.com/hochzeitsausstatterbachmann">Wir auf Facebook</Link>
          <Link to="https://www.instagram.com/hochzeitsausstatter/">Wir auf Instagram</Link>
        </section>
      </div>
    </footer>
  );
}
