export default function PhoneCall({ className }: Props): JSX.Element {
  return (
    <svg
      version="1.1"
      width="32"
      height="32"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 56 56"
      className={className}
    >
      <g>
        <path
          d="M12,31C5.383,31,0,36.383,0,43s5.383,12,12,12s12-5.383,12-12S18.617,31,12,31z M12,53C6.486,53,2,48.514,2,43
		s4.486-10,10-10s10,4.486,10,10S17.514,53,12,53z"
        />
        <path d="M18,42h-5v-5c0-0.553-0.447-1-1-1s-1,0.447-1,1v6c0,0.553,0.447,1,1,1h6c0.553,0,1-0.447,1-1S18.553,42,18,42z" />
        <path
          d="M55.421,41.904c-2.008-3.796-8.981-7.912-9.287-8.092c-0.896-0.51-1.831-0.78-2.706-0.78c-1.301,0-2.366,0.596-3.011,1.68
		c-1.02,1.22-2.285,2.646-2.592,2.867c-2.376,1.612-4.237,1.429-6.294-0.629L20.044,25.464c-2.045-2.045-2.233-3.928-0.631-6.291
		c0.224-0.31,1.649-1.575,2.87-2.596c0.778-0.463,1.312-1.15,1.545-1.995c0.307-1.106,0.071-2.463-0.652-3.732
		c-0.173-0.296-4.29-7.269-8.085-9.276C14.382,1.198,13.585,1,12.785,1c-1.319,0-2.558,0.514-3.49,1.445L6.758,4.982
		c-4.014,4.013-5.467,8.562-4.321,13.52c0.743,3.213,2.608,6.589,5.529,10.065C9.252,28.207,10.601,28,12,28c8.271,0,15,6.729,15,15
		c0,1.463-0.22,2.874-0.613,4.212c5.395,5.124,10.544,7.724,15.319,7.724c3.721,0,7.189-1.581,10.307-4.699l2.537-2.537
		C56.09,46.159,56.441,43.83,55.421,41.904z"
        />
      </g>
    </svg>
  );
}

type Props = {
  className?: string;
};
