import { LazyLoadImage } from "react-lazy-load-image-component";
import classNames from "classnames";
import { SliderImage } from "../../types";

import "./ImageSlider.scss";

export default function ImageSlider({ images, autoscroll }: Props) {
  return (
    <div className="image-slider">
      <div className={classNames("image-slider-wrapper", { autoscroll })}>
        {images.map((image, key) => (
          <LazyLoadImage
            className="image-slider-image"
            width={image.width}
            height={image.height}
            alt={image.alt}
            src={image.src}
            key={key}
          />
        ))}
      </div>
    </div>
  );
}

type Props = {
  images: SliderImage[];
  autoscroll?: boolean;
};
