export default function HintBoxSeven(): JSX.Element {
  return (
    <div>
      <h3>Tipp 7: Noch 1 Monat bis zur Hochzeit</h3>
      <p>
        Überprüfen Sie nochmals Ihre Wunschliste und den Hochzeitstisch und ggf.
        ergänzen Sie diese.
      </p>

      <p>
        Der Brautstrauß muss jetzt bestellt werden - Aufgabe des Bräutigams. Die
        Blumen für die Feier (Tischschmuck) und die Räumlichkeiten (auch
        Kirche/Standesamt), aber auch Autoschmuck müssen jetzt bestellt werden.
        Denken Sie auch an evtl. Sträuße für Trauzeuginnen und Brautjungfern
        sowie Blütenstreukörbchen für die Blumenkinder.
      </p>

      <p>
        Legen Sie die Transportmöglichkeiten für die Fahrt zum Standesamt, zur
        Kirche und zur Feier fest. Sehen Sie sich nach einem schönen Gästebuch
        um. Es bereitet Ihnen später - neben den Hochzeitsfotos - noch schöne
        Stunden.
      </p>

      <p>
        Machen Sie einen Besuch bei einer Kosmetikerin, lassen Sie sich für das
        Braut-Make-up beraten. Vereinbaren Sie auch einen weiteren Termin vor
        der Hochzeit. Schön braun am Hochzeitstag? Mit einer Sonnenkur beginnen!
        Überprüfen Sie alle Vorbereitungen und Formalitäten für die
        Hochzeitsreise.
      </p>

      <p>
        Laden Sie Freunde und Nachbarn formlos (evtl. telefonisch) zum
        Polterabend ein - aber rechnen Sie mit Überraschungen, denn manche
        Freunde sind erfindungsreich! Überlegen Sie, mit welchen Späßen Sie zu
        rechnen haben - aber lassen Sie auch Späße zu!
      </p>
    </div>
  );
}
