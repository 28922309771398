export default function HintBoxTwo(): JSX.Element {
  return (
    <div>
      <h3>Tipp 2: Noch 7 bis 6 Monate vor der Hochzeit</h3>
      <p>
        Wählen Sie ihre Hochzeitsgarderobe aus. Zur Wahl sollte man sich Zeit
        lassen, deshalb jetzt beginnen, da oftmals noch hier und da eine
        Änderung gemacht werden muss. Suchen Sie dazu ein Fachgeschäft
        (Hochzeitsausstatter) auf, dort kann man Sie bestens beraten.
      </p>

      <p>
        Vereinbaren Sie einen Termin ca. 6 Wochen vor der Hochzeit. Wo soll
        gefeiert werden? Ein Restaurant muss jetzt ausgewählt werden, damit die
        Termine auch passen. Holen Sie sich Preis- und Menüvorschläge ein.
        Vergleichen Sie diese und buchen Sie schnellstmöglich, ebenso ein
        Nebenzimmer. Überlegen Sie ruhig schon jetzt, wen Sie einladen wollen.
        Später kann man die Gästeliste immer noch ergänzen.
      </p>

      <p>
        Brauchen Sie einen Fotografen, so denken Sie jetzt daran, diesen
        auszuwählen und zu beauftragen. Hören Sie sich im Bekanntenkreis um, und
        machen Sie Preisvergleiche. Haben Sie jemanden aus Ihrem Umfeld, so
        klären Sie jetzt die Ausstattungsfrage und Ihre Ansprüche. Denn
        Hochzeitsfotos kann man später nicht noch einmal schießen. Wollen Sie
        auch Video-Aufnahmen von Ihrer Hochzeit, dann sollten es vielleicht
        nicht nur die von Onkel Herbert sein... Verfahren Sie dazu genau wie
        beim Fotografen. Vielleicht bekommen Sie den Service auch aus einer
        Hand.
      </p>

      <p>
        Eine Musikgruppe, ein Diskjockey, eine Showeinlage muss bestellt werden.
        Oder Sie beauftragen einen professionellen Hochzeitsservice mit der
        Organisation der gesamten Vorbereitung oder bestimmter Einzelbereiche
        für den großen Tag.
      </p>
    </div>
  );
}
