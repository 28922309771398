import "./GroomPage.scss";
import imageBoxData from "../resources/groom-page.json";
import { ImageBox } from "../../types";
import { ImagesPreviewBox } from "../components/ImagesPreviewBox";
import HintBoxFive from "../components/hints/HintBoxFive";
import { Fragment } from "react";
import {Helmet} from "react-helmet";
import HintBoxSix from "../components/hints/HintBoxSix";
import HintBoxSeven from "../components/hints/HintBoxSeven";

const imageBoxes = imageBoxData as unknown as ImageBox[];
const hints = [<HintBoxFive />, <HintBoxSix />, <HintBoxSeven />];

export default function GroomPage(): JSX.Element {
  return (
    <main className="groom-page">
      <Helmet>
        <title>Alles für den Bräutigam - Hochzeitsausstatter Bachmann</title>
        <meta
            name="description"
            content="Endecke unsere große Auswahl an Anzügen und genieße unsere individueller Beratung"
        />
      </Helmet>
      <section className="groom-page-content-box">
        <div className="groom-page-content">
          <h1>Für den Bräutigam</h1>
          <p>
            Da bei jeder Hochzeit das Brautkleid im Mittelpunkt steht sollte der
            Anzug in Farbe und Stil abgestimmt sein. Einen Termin im Laden
            sollte dir helfen ein rundum Packet für deinen schönsten Tag im
            Leben zu finden. Dein Anzug sollte perfekt sitzen und bequem sein.
            Nach unseren Erfahrungen nach entscheiden sich die meisten Männer
            relativ schnell. Sobald du deinen Anzug und die dazugehörigen
            Accessoires gefunden hast lassen sich Arm und Hosenlänge problemlos
            anpassen. Bitte beachte, dass auch Anzüge bis zu 3 Monaten
            Lieferzeit haben.
          </p>
        </div>
      </section>
      {imageBoxes.map((imageBox, key) => (
        <Fragment>
          <section className="groom-page-content-box" key={key}>
            <div className="groom-page-content">
              <ImagesPreviewBox {...imageBox} />
            </div>
          </section>
          {hints[key] !== undefined && (
            <section className="bride-page-content-box">
              <div className="bride-page-content">{hints[key]}</div>
            </section>
          )}
        </Fragment>
      ))}
    </main>
  );
}
