import { Fragment } from "react";
import { Helmet } from "react-helmet";
import "./BridePage.scss";
import imageBoxData from "../resources/bride-page.json";
import { ImageBox } from "../../types";
import { ImagesPreviewBox } from "../components/ImagesPreviewBox";
import HintBoxTwo from "../components/hints/HintBoxTwo";
import HintBoxThree from "../components/hints/HintBoxThree";
import HintBoxFour from "../components/hints/HintBoxFour";

const imageBoxes = imageBoxData as unknown as ImageBox[];
const hints = [<HintBoxTwo />, <HintBoxThree />, <HintBoxFour />];

export default function BridePage() {
  return (
    <main className="bride-page">
      <Helmet>
        <title>Alles für die Braut - Hochzeitsausstatter Bachmann</title>
        <meta
          name="description"
          content="Endecke unsere große Auswahl an Brautkleidern und genieße unsere individueller Beratung"
        />
      </Helmet>
        <section className="bride-page-content-box">
            <div className="bride-page-content">
                <h1>Neustart!!! ab dem 1.11.2023 Outlet</h1>
                <h2>Bitte nur mit Termin</h2>
            </div>
        </section>
      <section className="bride-page-content-box">
        <div className="bride-page-content">
          <h1>Für die Braut</h1>
          <p>
            Damit du bereits vor deinem Besuch bei uns einen Eindruck bekommst
            welche traumhaft schönen Kleider dich erwarten, kannst du hier schon
            mal durch (fast) alle unserer Modelle stöbern.
          </p>
        </div>
      </section>
      {imageBoxes.map((imageBox, key) => (
        <Fragment key={key}>
          <section className="bride-page-content-box">
            <div className="bride-page-content">
              <ImagesPreviewBox {...imageBox} />
            </div>
          </section>
          {hints[key] !== undefined && (
            <section className="bride-page-content-box">
              <div className="bride-page-content">{hints[key]}</div>
            </section>
          )}
        </Fragment>
      ))}
    </main>
  );
}
