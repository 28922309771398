import galleryData from "../resources/gallery-page.json";
import { Link, useParams } from "react-router-dom";
import { GalleryImage } from "../../types";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import "./GalleryPage.scss";
import { useCallback, useState } from "react";
import BrautIcon from "../components/icons/BrautIcon";
import BrautigamIcon from "../components/icons/BrautigamIcon";
import { Helmet } from "react-helmet";

const TO_TYPES = {
  brautkleider: "/fuer-die-braut/brautkleider",
  anzuege: "/fuer-den-braeutigam/anzuege",
  accessoires: "/accessoires",
  schuhe: "/schuhe-fuer-die-braut-und-braeutigam",
};

const TitlePrefix = {
  brautkleider: "Brautkleider für die Braut",
  anzuege: "Anzüge für den Bräutigam",
  accessoires: "Accessoires für die Braut",
  schuhe: "Schuhe für die Braut und Bräutigam",
};
export default function GalleryPage({ type }: Props): JSX.Element {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const { vendor = "" } = useParams();
  // @ts-ignore
  const images = (galleryData[type] && galleryData[type][vendor]
    ? // @ts-ignore
      galleryData[type][vendor]
    : Object.values(galleryData[type]).flat()) as unknown as GalleryImage[];
  const vendors = // @ts-ignore
  (galleryData[type] ? Object.keys(galleryData[type]) : []).filter(
    (v) => v !== vendor
  );
  const thumbs = images.map(({ thumb }) => thumb);
  const photos = images.map(({ image }) => image);
  const typeTitle = type.replace("ue", "ü").replace("ae", "ä");
  const vendorTitle = vendor
    .replace("-", " ")
    .replace("ue", "ü")
    .replace("ae", "ä");
  return (
    <main className={`gallery-page ${type}`}>
      <Helmet>
        <title>{`${TitlePrefix[type]} ${!!vendor ? `, ${vendorTitle}`: ""}` } - Hochzeitsausstatter Bachmann</title>
      </Helmet>
      <section className="gallery-page-content-box">
        <div className="gallery-page-content">
          <h1>
            {typeTitle} {vendorTitle}
          </h1>
          <Gallery photos={thumbs} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map((x) => ({
                    ...x,
                    caption: x.alt,
                    source: x.src,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
          <div className="gallery-page-content-images-links">
            {vendors.map((v, key) => (
              <Link
                key={key}
                to={`${TO_TYPES[type]}/${v}`}
                className={`gallery-page-content-images-link`}
              >
                {type === "brautkleider" && (
                  <BrautIcon width={16} height={16} />
                )}
                {type === "anzuege" && <BrautigamIcon width={16} height={16} />}
                {typeTitle} {v.replace("-", " ")}
              </Link>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
}

type Props = {
  type: "brautkleider" | "anzuege" | "accessoires" | "schuhe";
};
