export default function HintBoxFive(): JSX.Element {
  return (
    <div>
      <h3>Tipp 5: 2 bis 3 Monate vor der Hochzeit</h3>
      <p>
        Aufgebot bestellen, sobald alle Dokumente komplett sind. Legen Sie das
        Programm für die Hochzeitsfeierlichkeiten fest. Organisieren Sie
        Unterkünfte für auswärtige Gäste - bei Verwandten, Bekannten oder in
        Hotels/Pensionen. Reservieren Sie evtl. ein Hotelzimmer für die
        Hochzeitsnacht. Wenn nur im kleinen Rahmen gefeiert wird, dann evtl.
        einen großen Sektempfang organisieren.
      </p>

      <p>
        Sie können Tanzen? Wenn nicht, können Sie sich jetzt zum Tanzkurs für
        Brautpaare oder für Privatstunden in der Tanzschule anmelden.
      </p>

      <p>
        Wer keine 08/15-Hochzeitstorte haben will, muss jetzt bestellen. Ihre
        Wunschliste sollten Sie nochmal überprüfen und evtl. ergänzen. Eventuell
        zu einem christlichen Eheseminar anmelden.
      </p>

      <p>
        Überprüfen Sie nochmal Ihre Kalkulation der erwarteten Kosten für die
        Feier. Ernennen Sie bei Bedarf eine(n) "Zeremonienmeister/in", der/die
        den Ablauf der Feier überwacht.
      </p>
    </div>
  );
}
