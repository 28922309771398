export default function HintBoxFour(): JSX.Element {
  return (
    <div>
      <h3>Tipp 4: Noch 3 Monate bis zur Hochzeit</h3>
      <p>
        Besprechen Sie jetzt die kirchliche Trauung mit dem Pfarrer. Vergessen
        Sie dabei nicht die Auswahl der Musik.
      </p>

      <p>
        Vereinbaren Sie jetzt beim Fotografen den Termin. Sprechen Sie mit ihm
        die Hochzeitsfotos ab. Bereden Sie mit Ihm ein Drehbuch/Festkalender der
        Hochzeit. Sprechen Sie ruhig schon jetzt mit Ihrem Friseur über Ihre
        Wunschfrisur für die Hochzeit. Lassen Sie einen Termin zur Hochzeit
        vormerken. Stimmen Sie Ihre Gästeliste ab.
      </p>

      <p>
        Verschicken Sie Vermählungsanzeigen und Einladungskarten, legen Sie für
        auswertige Gäste einen Lageplan von Kirche/Standesamt und
        Restaurant/Hotel bei. Denken Sie dabei an Rückantwortkarten!
      </p>

      <p>
        Beantragen Sie Hochzeitsurlaub und buchen Sie die Hochzeitsreise.
        Überprüfen Sie die Reisepässe und Ausweise. Stellen Sie eine Wunschliste
        für Ihre Geschenke zusammen und buchen Sie Ihre Hochzeitsfahrzeug
        (Kutsche, Oldtimer o.ä.). Fragen Sie die vorgesehenen Trauzeugen und
        evtl. Brautjungfern.
      </p>

      <p>
        Lassen Sie in einem Fachgeschäft einen Geschenketisch aufbauen und
        informieren Sie Verwandte und Freunde darüber. Überprüfen Sie nochmals
        den Hochzeitstermin beim Standesamt und beim Pfarrer.
      </p>
    </div>
  );
}
