import React, { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

import "./Header.scss";
import BrautIcon from "./icons/BrautIcon";
import BrautigamIcon from "./icons/BrautigamIcon";
import AccessoiresIcon from "./icons/AccessoiresIcon";
import SchuhIcon from "./icons/SchuhIcon";
import MenuIcon from "./icons/MenuIcon";
import PhoneCall from "./icons/PhoneCall";

export default function Header(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const handleMenuClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const closeMenu = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <header className="header">
      <div className="header-mobile">
        <NavLink
          to="/"
          title="Hochzeitsausstatter Bachmann"
          className="header-mobile-home"
        >
          <img
            src="/images/logo.png"
            alt="Hochzeitsausstatter Bachmann"
            className="header-navigation-logo"
          />
        </NavLink>
        <a href="tel:+4934298 736837" className="header-mobile-meeting">
          <PhoneCall className="header-mobile-menu"  />
        </a>
        <span onClick={handleMenuClick}>
          <MenuIcon className="header-mobile-menu" />
        </span>
      </div>
      <div
        className={classNames("header-content-backdrop", { open: isOpen })}
        onClick={closeMenu}
      />
      <div className={classNames("header-content", { open: isOpen })}>
        <NavLink
          to="/"
          title="Hochzeitsausstatter Bachmann"
          className="header-content-home"
          onClick={closeMenu}
        >
          <img
            src="/images/logo.png"
            alt="Hochzeitsausstatter Bachmann"
            className="header-navigation-logo"
          />
        </NavLink>

        <nav
          itemProp="breadcrumb"
          itemScope
          itemType="https://schema.org/BreadcrumbList"
          className="header-navigation"
        >
          <ul className="header-navigation-items">
            <li
              className="header-navigation-item"
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              <NavLink
                to="/fuer-die-braut"
                title="Eglantine, Ladybird, Private"
                itemProp="item"
                className="header-navigation-item-link"
                onClick={closeMenu}
              >
                <BrautIcon />
                <span itemProp="name">Für die Braut</span>
              </NavLink>
            </li>
            <li
              className="header-navigation-item"
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              <NavLink
                to="/fuer-den-braeutigam"
                title="Wilvorst Anzüge und Accessoires"
                itemProp="item"
                className="header-navigation-item-link"
                onClick={closeMenu}
              >
                <BrautigamIcon />
                <span itemProp="name">Für den Bräutigam</span>
              </NavLink>
            </li>
            <li
              className="header-navigation-item"
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              <NavLink
                to="/accessoires"
                title="Accessoires von Emmerling"
                itemProp="item"
                className="header-navigation-item-link"
                onClick={closeMenu}
              >
                <AccessoiresIcon />
                <span itemProp="name">Accessoires</span>
              </NavLink>
            </li>
            <li
              className="header-navigation-item"
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              <NavLink
                to="/schuhe-fuer-die-braut-und-braeutigam"
                title="Elsa Coloured Shoes"
                itemProp="item"
                className="header-navigation-item-link"
                onClick={closeMenu}
              >
                <SchuhIcon />
                <span itemProp="name">Schuhe</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
