import "./NotFoundPage.scss";
import {Link} from "react-router-dom";


export default function NotFoundPage(): JSX.Element {
    return (
        <main className="not-found-page">
            <section className="not-found-page-content-box">
                <div className="not-found-page-content">
                    <h1>Seite nicht gefunden</h1>
                    <p>
                        Bitte versuchen Sie zur Startseite zurückzukehren und erneut zu starten. Möglicherweise ist die Adresse, die Sie aufgerufen haben, nicht mehr vorhanden.
                    </p>
                    <p>
                        <Link to="/">Zur Startseite</Link>
                    </p>
                </div>
            </section>
        </main>
    );
}
