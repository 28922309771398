export default function HintBoxThree(): JSX.Element {
  return (
    <div>
      <h3>Tipp 3: 5 bis 4 Monate vor der Hochzeit</h3>
      <p>
        Informieren Sie sich über "Dankeschön"-Geschenke für die
        Hochzeits-Präsente.
      </p>
      <p>Weisen Sie die Trauzeugen auf die nötigen Papiere hin.</p>
      <p>
        Nebenzimmer und Menü im Restaurant/Hotel bestellen. Feiern Sie im
        Restaurant oder beauftragen Sie einen Lieferservice, so sollten Sie sich
        ruhig schon jetzt ein/zwei gemütliche Abende gönnen und Speisen vorab
        probieren, um eine gute Wahl zu treffen. Gleiches gilt auch für Weine.
        Einladungskarten, Tischkarten, Menükarten bedürfen der Planung.
        Vorlagen, Zeichnungen, Bilder, Grafiken, Texte müssen ausgewählt werden.
        Holen Sie dazu Angebote von Druckereien ein. Vergleichen Sie die
        Angebote und vergeben Sie den Druckauftrag.
      </p>
      <p>
        Überlegen Sie sich das Ziel für Ihre Hochzeitsreise (denken Sie dabei
        auch an evtl. Impfungen).
      </p>
    </div>
  );
}
