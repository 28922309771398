import { Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect } from "react";

import Header from "./app/components/Header";
import Footer from "./app/components/Footer";
import IndexPage from "./app/pages/IndexPage";
import BridePage from "./app/pages/BridePage";
import GroomPage from "./app/pages/GroomPage";
import GalleryPage from "./app/pages/GalleryPage";
import ImprintPage from "./app/pages/ImprintPage";
import NotFoundPage from "./app/pages/NotFoundPage";
import PrivacyPage from "./app/pages/PrivacyPage";

import "./App.scss";

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/fuer-die-braut" element={<BridePage />} />
        <Route
          path="/fuer-die-braut/brautkleider/:vendor"
          element={<GalleryPage type="brautkleider" />}
        />
        <Route path="/fuer-den-braeutigam" element={<GroomPage />} />
        <Route
          path="/fuer-den-braeutigam/anzuege/:vendor"
          element={<GalleryPage type="anzuege" />}
        />
        <Route path="/impressum" element={<ImprintPage />} />
        <Route path="/datenschutz" element={<PrivacyPage />} />
        <Route
          path="/accessoires/:vendor"
          element={<GalleryPage type="accessoires" />}
        />
        <Route
          path="/accessoires"
          element={<GalleryPage type="accessoires" />}
        />
        <Route
          path="/schuhe-fuer-die-braut-und-braeutigam"
          element={<GalleryPage type="schuhe" />}
        />
        <Route
          path="/schuhe-fuer-die-braut-und-braeutigam/:vendor"
          element={<GalleryPage type="schuhe" />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </div>
  );
}
