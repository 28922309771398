import { Fragment } from "react";
import {ImageBox} from "../../types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {Link} from "react-router-dom";

export function ImagesPreviewBox({
  images,
  to,
  title,
  className,
}: ImageBox): JSX.Element {
  const largeImages = images.slice(0, 2);
  const shortImages = images.slice(2);
  return (
    <Fragment>
      <h2>{title}</h2>
      <div className={`${className}-images`}>
        {largeImages.map((image, key) => (
            <div className={`${className}-images-img`} key={key}>
              <LazyLoadImage
                  src={image.src}
                  alt={image.alt}
              />
            </div>
        ))}
      </div>
      <div className={`${className}-images`}>
        {shortImages.map((image, key) => (
            <div className={`${className}-images-img`} key={key}>
              <LazyLoadImage
                  src={image.src}
                  alt={image.alt}
              />
            </div>
        ))}
      </div>
      <Link to={to} className={`${className}-images-link`}>Alle anzeigen</Link>
    </Fragment>
  );
}

