import "./ImprintPage.scss";
import {Helmet} from "react-helmet";

export default function ImprintPage(): JSX.Element {
  return (
    <main className="imprint-page">
      <Helmet>
        <title>Impressum - Hochzeitsausstatter Bachmann</title>
      </Helmet>
      <section className="imprint-page-content-box">
        <div className="imprint-page-content">
          <h1>Impressum</h1>
          <p>
            <span>Hochzeitsausstatter Bachmann</span>
            <span>Inhaberin/Geschäftsführung: Verena Bachmann</span>
          </p>
          <p>
            <span>Wurzner Straße 27</span>
            <span>D-04425 Taucha</span>
            <span>Telefon: +49 34298 736837</span>
            <span>E-Mail: brautmode@gmx.net</span>
          </p>
          <p>
            <span>Umsatzsteuer-ID: DE814591754</span>
            <span>Inhaltlich verantwortlich: Verena Bachmann</span>
          </p>
          <p>
            Bitte besuchen Sie unser Geschäft in Taucha oder schreiben Sie uns
            eine eMail, wenn Sie Fragen haben! Bitte beachten Sie das die Kosten
            für Schneiderarbeiten (Änderungen) nicht in den Preisen für die auf
            dieser Website gezeigten Artikel enthalten sind!!! Bitte vorher
            Termin vereinbaren!{" "}
          </p>
          <p>
            © Fotos: Eglantine, Ladybird, Fuchs, Wilvorst und Elsa Coloured
            Shoes
          </p>
        </div>
      </section>
    </main>
  );
}
